module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Sydney Ecommerce Theme","short_name":"Sydney","start_url":"/","background_color":"#000000","theme_color":"#ffffff","display":"standalone","icon":"src/assets/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1d2e8b0002863acfe79a858a4fabcb13"},
    },{
      plugin: require('../node_modules/gatsby-plugin-snipcart-advanced/gatsby-browser.js'),
      options: {"plugins":[],"version":"3.0.29","publicApiKey":"OTNkZTJlZDMtZmNhZi00MzQzLThiM2YtNzIwY2M1MjE5OGFjNjM3MTE0NjQyNTAyOTkxNjE5","defaultLang":"en","currency":"usd","openCartOnAdd":true,"autopop":true,"useSideCart":true,"locales":{"fr":{"actions":{"checkout":"Valider le panier"}}},"templatesUrl":"path on your template file. Set file in the static folder, ex: '/snipcart/index.html'","innerHTML":"\n            <billing section=\"bottom\">\n                <!-- Customization goes here -->\n            </billing>"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
